import axios, { type AxiosRequestConfig } from 'axios';
import { saveAs } from 'file-saver';

export const getSuggestionsData = async (value: any) => {
  try {
    const res = await axios.post(`/api/seoRoute?path=course/cf/old`, value);
    return res.data;
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const getSuggestionsDataV2 = async (
  value: any,
  aggregation = true,
  sameCategory = false,
) => {
  try {
    const res = await axios.post(
      `/api/geebeeRoute?path=/cf?aggregation=${aggregation}&sameCategory=${sameCategory}`,
      value,
    );
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    console.log(`Something went wrong. Please contact the tech team`);
  }
};

export const getCFKeywordSuggestions = async (value: any) => {
  try {
    const res = await axios.post(
      `/api/geebeeRoute?path=/cf/keywords/suggestion`,
      value,
    );
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    console.log(`Something went wrong. Please contact the tech team`);
  }
};

export const getShortlistData = async (studentId: any) => {
  try {
    const res = await axios.get(
      `/api/geebeeRoute?path=/v1/shortlist/student/${studentId}`,
    );
    return res.data;
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const addToShortlist = async (studentId: any, value: any) => {
  try {
    const res = await axios.post(
      `/api/geebeeRoute?path=/v1/shortlist/student/${studentId}`,
      value,
    );
    return res.data;
  } catch (err: any) {
    console.log(`Something went wrong. Please contact the tech team`);
  }
};

export const getTofuLeadDetails = async (userId: number) => {
  try {
    const res = await axios.get(`/api/router?path=cf/student/info/${userId}`);
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    console.log(`Something went wrong. Please contact the tech team`);
  }
};

export const addToBookmarkV2 = async (value: any) => {
  try {
    const res = await axios.post(`/api/router?path=cf/addbookmark`, value);
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const deleteFromBookmarkV2 = async (bookmarkId: any) => {
  try {
    const res = await axios.delete(
      `/api/router?path=cf/bookmark/${bookmarkId}`,
    );
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const verifyStudentPrefernces = async (value: any) => {
  try {
    const res = await axios.post(
      `/api/router?path=cf/validate/student-preference`,
      value,
    );
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const getBookmarkShortlistStatus = async (leadId: string) => {
  try {
    const res = await axios.get(
      `/api/router?path=cf/bookmark/status/${leadId}`,
    );
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const getBookmarks = async (leadId: string) => {
  try {
    const res = await axios.get(`/api/router?path=cf/bookmark/${leadId}`);
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const getStudentPreference = async (leadId: string) => {
  try {
    const res = await axios.get(
      `/api/router?path=cf/student-preference/${leadId}`,
    );
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const getStudentBasicInfo = async (leadId: string) => {
  try {
    const res = await axios.get(`/api/router?path=cf/student/basic/${leadId}`);
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const addToShortlistV2 = async (value: any) => {
  try {
    const res = await axios.post(`/api/router?path=cf/shortlist`, value);
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const getPreferenceNotCovered = async (leadId: string) => {
  try {
    const res = await axios.get(
      `/api/router?path=cf/preferences-not-covered/${leadId}`,
    );
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

// for type ahead fields for College Name
export const collegeNameSuggestions = async (collegeName: string) => {
  const data = {
    collegeName: collegeName,
  };

  try {
    const res = await axios.post(
      `/api/seoRoute?path=college/suggestions`,
      data,
    );
    if (res.data.success) {
      return res?.data?.data;
    }
    throw new Error(res.data.message);
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};
// export const streamNameSuggestion = async (stream: string) => {
//   const data = {
//     streamName: stream,
//   };
//   try {
//     const res = await axios.post(
//       `/api/seoRoute?path=/course/stream/suggestions`,
//       data,
//     );
//     if (res.data.success) {
//       return res?.data?.data;
//     }
//     throw new Error(res.data.message);
//   } catch (err) {
//     throw Error(`Something went wrong. Please contact the tech team`);
//   }
// };
// export const cityNameSuggestions = async (cityName: string) => {
//   const data = {
//     cityName: cityName,
//   };
//   try {
//     const res = await axios.post(
//       `/api/seoRoute?path=/cities/suggestions`,
//       data,
//     );
//     if (res.data.success) {
//       return res?.data?.data;
//     }
//     throw new Error(res.data.message);
//   } catch (err) {
//     throw Error(`Something went wrong. Please contact the tech team`);
//   }
// };
export const getCountry = async () => {
  try {
    const res = await axios.get(`/api/seoRoute?path=country/listAll`);
    const countriesList = [] as any;
    if (res?.data?.success) {
      const list = res?.data?.data?.countriesList;
      for (let i = 0; i < list.length; i++) {
        countriesList.push({
          label: list[i]?.countryName,
          value: list[i]?.countryId,
        });
      }
      return countriesList;
    }
    throw new Error(res.data.message);
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};
export const getCountryValue = async () => {
  try {
    const res = await axios.get(`/api/seoRoute?path=country/listAll`);
    const countriesList = [] as any;
    if (res?.data?.success) {
      const list = res?.data?.data?.countriesList;
      for (let i = 0; i < list.length; i++) {
        countriesList.push({
          label: list[i]?.countryName,
          value: list[i]?.countryName,
        });
      }
      return countriesList;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};
export const getCountryValueWithCountryID = async () => {
  try {
    const res = await axios.get(`/api/seoRoute?path=country/listAll`);
    const countriesList = [] as any;
    const countryId = {} as any;
    if (res?.data?.success) {
      const list = res?.data?.data?.countriesList;
      for (let i = 0; i < list.length; i++) {
        countriesList.push({
          label: list[i]?.countryName,
          value: list[i]?.countryName,
        });
        countryId[list[i]?.countryName] = list[i]?.countryId;
      }
      return { countriesList, countryId };
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};
// export const getFeeRange = async () => {
//   try {
//     const res = await axios.get(`/api/seoRoute?path=/course/tuitionFeeRange`);
//     if (res.data.success) {
//       return res.data;
//     }
//     throw new Error(res.data.message);
//   } catch (err) {
//     throw new Error(`Something went wrong. Please contact the tech team`);
//   }
// };
// export const getDurationRange = async () => {
//   try {
//     const res = await axios.get(
//       `/api/seoRoute?path=/course/courseDurationRange`,
//     );
//     if (res.data.success) {
//       return res.data;
//     }
//     throw new Error(res.data.message);
//   } catch (err) {
//     throw new Error(`Something went wrong. Please contact the tech team`);
//   }
// };
export const getTypeOfProgram = async () => {
  try {
    const res = await axios.get(`/api/seoRoute?path=course/degrees/listAll`);
    const programsList = [] as any;
    if (res?.data?.success) {
      const list = res?.data?.data?.degreesList;
      for (let i = 0; i < list.length; i++) {
        programsList.push({
          label: list[i]?.degreeName,
          value: list[i]?.degreeId,
        });
      }
      return programsList;
    }
    throw new Error(res.data.message);
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};
export const courseNameSuggestions = async ({
  value,
  universityId,
  countryId,
  degreeId,
}: any) => {
  const data = {
    name: value,
    universityIds: universityId,
    countryIds: countryId,
    degreeIds: degreeId,
  };
  try {
    const res = await axios.post(`/api/seoRoute?path=course/suggestions`, data);
    if (res.data.success) {
      return res?.data?.data;
    }
    throw new Error(res.data.message);
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};
export const courseCategoryListSuggestions = async (value: any) => {
  const data = {
    subStreamName: value,
  };

  try {
    const res = await axios.post(
      `/api/seoRoute?path=course/category/suggestions`,
      data,
    );
    if (res.data.success) {
      return res?.data;
    }
    throw new Error(res.data.message);
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

// for type ahead fields for Course Name
export const courseCategorySuggestions = async () => {
  try {
    const res = await axios.get(
      `/api/router?path=user/course-category-suggestions`,
    );
    if (res.data.success) {
      return res.data;
    }
    throw new Error(res.data.message);
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};
// for fetching initial table data
export const getInitialRecommendationsData = async (leadId: string) => {
  try {
    const res = await axios.get(
      `/api/router?path=user/colleges-recommendations/${leadId}?final=false`,
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

// add college manually by a form
export const addManually = async (leadId: string, payload: any) => {
  try {
    const res = await axios.post(
      `/api/router?path=user/college-recommendations/add/${leadId}`,
      payload,
      {
        headers: {
          'Content-Type': `application/json`,
        },
      },
    );
    if (res.data.success) {
      return res.data;
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

// adding college by uploading CSV
export const uploadInitialRecommendations = async (
  file: File,
  leadId: string,
) => {
  try {
    const formData = new FormData();
    formData.append(`file`, file);
    const res = await axios.post(
      `/api/upload?path=user/colleges-recommendations/upload/${leadId}`,
      formData,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      },
    );
    if (res.data.success) {
      const downloadableFile = convertJsonToCsv(res.data.data);
      saveAs(downloadableFile, `recommendations.csv`);
      return res.data;
    } else if (!res.data.success && res.data.data) {
      const downloadableFile = convertJsonToCsv(res.data.data);
      saveAs(downloadableFile, `Bookmarks.csv`);
      return res.data;
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

// for changing the state from initial to final table of a particular id
export const moveToFinal = async (leadId: string, id: any) => {
  let res;
  try {
    res = await axios.post(
      `/api/router?path=counsellor/application/${leadId}/${id}/RECOMMENDATION_GIVEN`,
    );
    if (res?.data?.success) {
      return res.data;
    } else {
      return res?.data?.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const moveToInitial = async (leadId: string, id: any) => {
  let res;
  try {
    res = await axios.post(
      `/api/router?path=counsellor/application/${leadId}/${id}/INITIAL_SHORTLIST`,
    );
    if (res?.data?.success) {
      return res.data;
    } else {
      return res?.data?.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const getFinalRecommendations = async (leadId: string) => {
  try {
    const res = await axios.get(
      `/api/router?path=user/colleges-recommendations/${leadId}?final=true`,
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const editRecommendations = async (leadId: string, payload: any) => {
  try {
    const res = await axios.post(
      `/api/router?path=user/colleges-recommendations/${leadId}`,
      payload,
    );
    if (res.data.success) {
      return res.data;
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const deleteRecommendations = async (leadId: string, id: any) => {
  let res;
  try {
    res = await axios.post(
      `/api/router?path=counsellor/application/${leadId}/${id}/ARCHIVED`,
    );
    if (res?.data?.success) {
      return res.data;
    } else {
      return res?.data?.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const checkNextState = async (leadId: string) => {
  try {
    const res = await axios.get(`/api/router?path=user/next-states/${leadId}`, {
      headers: {
        'Content-Type': `application/json`,
      },
    });
    return res;
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const stateUpdate = async (leadId: string, state: string) => {
  try {
    const res = await axios.post(
      `/api/router?path=user/pre/${leadId}/state/${state}`,
      {
        headers: {
          'Content-Type': `application/json`,
        },
      },
    );
    return res;
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const uploadFinalRecommendations = async (
  file: File,
  leadId: string,
) => {
  try {
    const formData = new FormData();
    formData.append(`file`, file);
    const res = await axios.post(
      `/api/upload?path=student/colleges/upload/${leadId}`,
      formData,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      },
    );
    if (res.data.success) {
      const downloadableFile = convertJsonToCsv(res.data.data);
      saveAs(downloadableFile, `recommendations.csv`);
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const replaceFile = async (file: File, version: any, leadId: string) => {
  try {
    const formData = new FormData();
    formData.append(`file`, file);
    const res = await axios.post(
      `/api/upload?path=artifact/upload/${leadId}/FIRST/${version}`,
      formData,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      },
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const downloadFile = async (id: number) => {
  try {
    const config: AxiosRequestConfig = {
      method: `GET`,
      url: `/api/download?path=artifact/download/${id}`,
      responseType: `blob`,
    };
    const res = await axios(config);
    if (res && res.data && res.data.size > 0) {
      const fileName = res.headers[`content-disposition`].split(`filename=`)[1];
      saveAs(res.data, fileName);
      return true;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const notifyUser = async (
  id: number,
  stage: string,
  leadId: string,
): Promise<boolean> => {
  try {
    const res = await axios.post(
      `/api/router?path=counsellor/notify/${stage}/${leadId}/${id}`,
      {},
    );
    return true;
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

// download initalRecomendation in new flow
export const initalRecommendationsDownload = (recommendations: any) => {
  try {
    const downloadableFile = convertJsonToCsv(recommendations);
    saveAs(downloadableFile, `InitialRecommendations.csv`);
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

// send initalRecomendation in new flow
export const sendInitialNotification = async (
  leadId: string,
): Promise<boolean> => {
  try {
    const res = await axios.post(
      `/api/router?path=user/initial-colleges-recommendations/notify/${leadId}`,
      {},
    );
    return true;
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const finalRecommendationsDownload = (recommendations: any) => {
  try {
    const downloadableFile = convertJsonToCsv(recommendations);
    saveAs(downloadableFile, `recommendations.csv`);
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

const convertJsonToCsv = (data: any): any => {
  data.forEach((val: any) =>
    val.preferredIntake
      ? (val.preferredIntake = val.preferredIntake.join(`, `))
      : ``,
  );
  data.forEach((val: any) =>
    val.nextStages ? (val.nextStages = val.nextStages.join(`, `)) : ``,
  );
  const replacer = (key: any, value: any) => (value === null ? `` : value);
  const header = Object.keys(data[0]);
  const newheader = header.map((elem) => {
    if (elem === `toefl`) {
      return `TOEFL Requirement`;
    } else if (elem === `courseUrl`) {
      return `University-Course Page Program Link`;
    } else {
      return elem.replace(/([A-Z])/g, ` $1`).replace(/^./, function (str) {
        return str.toUpperCase();
      });
    }
  });
  let csv = data.map((row: any) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(`,`),
  );
  csv.unshift(newheader.join(`,`));
  csv = csv.join(`\r\n`);
  const blob = new Blob([csv], { type: `text/csv;charset=utf-8` });
  return blob;
};

export const saveApplication = async (
  values: Record<string, any>,
  appId: string,
  leadId: string,
) => {
  try {
    const res = await axios.post(
      `/api/router?path=counsellor/final-recommendation/${leadId}/${appId}`,
      values,
    );
    return res;
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const changeApplicatonStage = async (
  leadId: string,
  applicationId: number | string,
  selectedApplicationStatus: string,
) => {
  try {
    const res = await axios.post(
      `/api/router?path=counsellor/application/${leadId}/${applicationId}/${selectedApplicationStatus}`,
    );
    return res;
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const getUserRoleType = async () => {
  try {
    const res = await axios.get(`/api/router?path=user/roles`);
    return res;
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const verifyUploadedDocs = async (
  leadId: string,
  applicationId: number | string,
) => {
  try {
    const res = await axios.get(
      `/api/router?path=counsellor/missing-documents/${leadId}/${applicationId}/`,
    );
    return res;
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const updateMissingDocReason = async (values: any) => {
  try {
    const res = await axios.post(
      `/api/router?path=document/missing-document-reason/`,
      values,
    );
    if (res?.data?.success) {
      return res;
    } else {
      return res?.data?.message;
    }
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

// old initial Recommendations
export const getOldInitialRecommendations = async (leadId: string) => {
  try {
    const res = await axios.get(
      `/api/router?path=user/initial-recommendation-docs/${leadId}`,
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const getVisaDetails = async (leadId: any) => {
  let res;
  try {
    res = await axios.get(`/api/router?path=user/visa-status/${leadId}`);
    if (res?.data?.success) {
      return res?.data?.data;
    } else {
      return res?.data?.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const saveVisaDetail = async (values: any) => {
  let res;
  try {
    res = await axios.post(`/api/router?path=user/change/visa-status`, values);
    return res;
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const assignCounsellor = async (id: any) => {
  let res;
  try {
    res = await axios.post(
      `/api/router?path=user/visa/assign-counsellor/${id}`,
    );

    return res;
  } catch (err: any) {
    console.log(`err`, err);
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const savePriority = async (value: any, id: any) => {
  let res;
  try {
    res = await axios.post(
      `/api/router?path=user/colleges-recommendations/priority/${id}`,
      { priority: value },
    );
    if (res?.data?.success) {
      return res;
    } else {
      return res?.data?.message;
    }
  } catch (err: any) {
    throw Error(err || `Something went wrong. Please contact the tech team`);
  }
};

export const getVisaCounsellors = async () => {
  let res;
  try {
    res = await axios.get(`/api/router?path=user/list/VISA_COUNSELLOR`);
    if (res?.data?.success) {
      return res?.data?.data;
    } else {
      return res?.data?.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const fetchUploadDocsData = async (data: any) => {
  const { keyName, studentId, applicationId } = data;
  try {
    const res = await axios.get(
      `/api/router?path=document/${keyName}/${studentId}/${applicationId}`,
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const uploadDocsData = async (datas: any) => {
  const { file, data, leadId, title, initialGeebeeData } = datas;
  try {
    const formData = new FormData();
    formData.append(`file`, file);
    let url = ``;
    url = `/api/upload?path=document/${data?.keyName}/${
      data?.id || 0
    }/${leadId}/${initialGeebeeData?.applicationId}`;
    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    });
    if (res.data.success) {
      return res.data.data;
    } else {
      return res.data.message;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const downloadDocsData = async (data: any) => {
  const downloadUrl = `document/download/${data?.keyName}/${data?.id}`;
  try {
    const config: AxiosRequestConfig = {
      method: `GET`,
      url: `/api/download?path=${downloadUrl}`,
      responseType: `blob`,
    };
    const res = await axios(config);
    if (res && res.data && res.data.size > 0) {
      const fileName = res.headers[`content-disposition`].split(`filename=`)[1];
      saveAs(res.data, fileName);
    }
  } catch (err) {
    throw new Error(`Something went wrong. Please contact the tech team`);
  }
};
export const viewDocsData = async (datas: any) => {
  const { leadId, d, initialGeebeeData } = datas;
  try {
    const res = await axios.get(
      `/api/router?path=document/viewdoc/${d?.id}/${leadId}/${initialGeebeeData?.applicationId}/${d?.keyName}`,
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};
export const statusUpdateDocs = async (datas: any) => {
  console.log(datas, `datas`);
  const { payload, initialGeebeeData } = datas;
  const { status, id, leadId, values, data } = payload;
  const payloadData = {
    rejectReason: values,
  };
  try {
    let res;
    if (status == `ACCEPTED`) {
      res = await axios.post(
        `/api/router?path=document/doc/status/${status}/${data?.id}/${leadId}/${initialGeebeeData?.applicationId}/${data?.keyName}`,
        { rejectReason: null },
      );
    } else {
      res = await axios.post(
        `/api/router?path=document/doc/status/${status}/${data?.id}/${leadId}/${initialGeebeeData?.applicationId}/${data?.keyName}`,
        payloadData,
      );
    }

    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const getVisaRemarks = async (data: any) => {
  const { visaId } = data;
  try {
    const res = await axios.get(
      `/api/router?path=student-visa/${visaId}/remarks`,
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const addVisaRemarks = async (data: any) => {
  const { visaId, remark, studentId, universityPartner } = data;

  const payload = {
    scholarVisaId: visaId,
    remark,
    scholarStudentId: studentId,
    universityPartner,
  };

  try {
    const res = await axios.post(
      `/api/router?path=student-visa/remarks`,
      payload,
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};

export const getCourseDetails = async (data: any) => {
  try {
    const { studentId, courseId } = data;
    const res = await axios.get(
      `/api/geebeeRoute?path=/cf/${courseId}${
        studentId ? `?studentId=${data.studentId}` : ``
      }`,
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      return false;
    }
  } catch (err) {
    throw Error(`Something went wrong. Please contact the tech team`);
  }
};
